.bottomMenu {
  color: white;
  position: fixed;
  z-index: 100;
  bottom: 0px;
  left: 0;
  width: 100%;
  
  background-color: black;
  //transform: translate(0px, calc(100% - 30px));
  //position: absolute;

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    //bottom: 20px;
    padding-bottom: 20px;
  }


  ul,
  ol {
    list-style-type: none;
    padding-left: 0;
  }

  ul {
    display: flex;
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(lg) {
      //height: 100px;
      max-width: 650px;
    }
  }

  li {
    flex: 1 1 20%;
    text-align: center;
  }
}


.bottomMenu__li {
  transition: all 0.5s ease-out;
  &:hover, &:focus, &.selected {
    background-color: rgba(#000, 0.1);
  }
}

.bottomMenu__link {

  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 5px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0.7;
  
  //padding-bottom: 10px;
  font-family: $family-header;

  * {
    position: relative;
    z-index: 100;
  }

  .icon {
    // width: 32px;
    // height: 32px;
    width: 20px;
    height: 20px;
    //@include bg-img();
    margin: auto;
    //transition: all 0.3s ease-out;
  }

  div {
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 0.3rem;
    font-weight: $weight-bold;
  }

  &:hover,
  &:focus,
  &.selected {
    //transform: translate(0, -2px);
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 5px;

    .icon {
      // width: 40px;
      // height: 40px;
      width: 28px;
      height: 28px;
    }

    div {
      font-size: 11px;
      letter-spacing: 0.5px;
    }
  }
}

.icon {
  img { width: 100%; height: 100%; }
  .normal { display: block; }
  .selected { display: none; }
}

.bottomMenu__link:hover .icon,
.bottomMenu__link.selected .icon {
  .normal { display: none; }
  .selected { display: block; }
}


//https://tympanus.net/Development/IconHoverEffects/#set-8