.modal {
  background: rgba(#000, 0.5);
  color: white;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__content {
  width: 100%;
  max-width: 600px;
  background-color: #000;
  padding: 40px 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  position: relative;
}
.modal__content h3 {
  color: white;
  margin-bottom: 2rem;
}
.modal__content .no_message {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: rgba(17, 17, 17, 0.9);
  padding: 20px;
  transition: 0.3s ease all;
  margin-bottom: 1rem;
}
.modal__btns {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.modal__btns button {
  background-color: rgb(255, 255, 255);
  color: rgb(29, 33, 41);
  font-size: 20px;
  border-radius: 5px;
  box-sizing: border-box !important;
  outline: none !important;
  width: 45%;
  border: none;
  transform: translateY(0);
  transition: 0.3s ease all;
  padding: 0.5rem 2rem;
  &:hover {
    transform: translateY(-3px);
    transition: 0.3s ease all;
  }
}
.modal__btns .btn_no {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}
.modal_logo {
  width: 52%;
  margin-bottom: 1rem;
}
.modal_logo img {
  max-width: 100%;
}
.modal__btns select {
  width: 100%;
  padding: 0 14px;
}
.close_btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #fff;
  width: 1.2rem;
  height: 1.2rem;
}
