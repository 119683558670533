.stripeBuyBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stripeBuyBtn div.button {
  color: white;
  background: $primary-color;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover {
    background: $link-hover-color;
    text-decoration: none;
  }
}
.stripeBuyBtn a.buttona {
  color: white;
  background: $primary-color;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  &:focus,
  &:hover {
    background: $link-hover-color;
    text-decoration: none;
  }
}
